<template>
  <!-- 自发货订单 -->
  <div
    class="inboundManual"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  > 
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane :label="'待处理'+`(${waitDealCount})`" name="1"></el-tab-pane>
      <el-tab-pane :label="'待发货'+`(${waitSendCount})`" name="2"></el-tab-pane>
      <el-tab-pane label="已发货" name="3"></el-tab-pane>
      <el-tab-pane label="已取消" name="4"></el-tab-pane>
    </el-tabs>
    <!-- 待处理筛选条件 -->
    <el-row type="flex" disabled="start" style="margin-top: 20px" v-show="activeName=='1'?true:false">
      <el-button 
       v-for="(item, index) in buttonGroupList" 
       :label="item.name+''+(item.value)" 
       :key="item.id"
       @click="search(pageList.profileId, tabName, item.id)"
      >{{item.name}}({{item.value}})</el-button>
    </el-row>
    <!-- 筛选条件 -->
    <div
      class="queryClass"
      v-show="activeName=='0'||activeName=='1'||activeName=='2'||activeName=='3'||activeName=='4'?true:false">
       <el-select clearable placeholder="全部店铺" v-model="pageList.profileId" class="el_elsect">
        <el-option 
          v-for="item in shopList"
          :key="item.id"
          :label="item.name"
          :value="item.id+'-'+item.marketPlaceId"
          @click.native="checkGroup(item.id, item.marketPlaceId)"
         >{{ item.name }}
        </el-option>
       </el-select>
       <el-select multiple clearable collapse-tags class="el_elsect" placeholder="发货仓库" v-model="pageList.storageId">
        <el-option
         v-for="item in storageList"
         :key="item.id"
         :label="item.value"
         :value="item.id"
        ></el-option>
       </el-select>
       <el-select clearable class="el_elsect" placeholder="全部平台" v-model="pageList.platform">
        <el-option
         v-for="item in platformList"
         :key="item.id"
         :label="item.value"
         :value="item.id"
        ></el-option>
       </el-select>
       <el-select multiple clearable collapse-tags="true" class="el_elsect" placeholder="物流商" v-model="pageList.provider">
        <el-option
         v-for="item in providerList"
         :key="item.id"
         :label="item.value"
         :value="item.id"
        ></el-option>
       </el-select>
       <el-select multiple clearable collapse-tags class="el_elsect" placeholder="物流方式" v-model="pageList.logisticsMode">
        <el-option
         v-for="item in supplyModeList"
         :key="item.id"
         :label="item.trackWay"
         :value="item.id"
        ></el-option>
       </el-select>
       <el-select multiple clearable filterable collapse-tags class="el_elsect" placeholder="订单标签" v-model="pageList.orderTags">
        <el-option
         v-for="item in orderTagList"
         :key="item.id"
         :label="item.value"
         :value="item.id"
        ></el-option>
       </el-select>
       <el-select multiple clearable collapse-tags class="el_elsect" placeholder="订单状态" v-model="pageList.orderState" v-show="activeName=='0'?true:false">
        <el-option
         v-for="item in orderStateList"
         :key="item.id"
         :label="item.value"
         :value="item.id"
        ></el-option>
       </el-select>
       <el-select clearable placeholder="请选择类型" v-model="pageList.dateType" class="el_elsect">
        <el-option label="订购时间" value="0"></el-option>
        <el-option label="发货时限" value="1"></el-option>
       </el-select>
       <el-date-picker
        v-model.trim="chooseDate"
        type="daterange"
        format="yyyy年MM月dd日"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="margin-right: 15px"
        >
       </el-date-picker>
    </div>
    <div
     class="queryClass"
     v-show="activeName=='0'||activeName=='1'||activeName=='2'||activeName=='3'||activeName=='4'?true:false">
      <el-input style="width:360px;" v-model="inputContent" placeholder="请输入搜索内容">
          <el-select 
            clearable
            @clear="clearAsin"
            slot="prepend"
            v-model="selectContent" 
            placeholder="请选择" 
            style="width: 120px"
            >
             <el-option
              v-for="item in selectList"
              :key="item.id"
              :label="item.value"
              :value="item.id">
             </el-option>
          </el-select>
      </el-input>
      <el-button icon="el-icon-refresh" style="margin-left: 15px" @click="reset">重置</el-button>
      <el-button icon="el-icon-search" type="primary" @click="search(pageList.profileId, tabName)">查询</el-button>
    </div>
    <!-- 按钮组 -->
    <el-row type="flex" justify="start">
      <!-- 全部/待发货/已发货 -->
      <div class="buttonGroup" v-show="activeName=='0'||activeName=='2'||activeName=='3'?true:false">
        <el-button @click="handleCustomerTips">客服备注</el-button>
        <el-button @click="handleMarkTag">标记标签</el-button>
      </div>
      <div class="buttonGroup" v-show="activeName=='1'?true:false">
        <el-button type="primary" @click="handleCheck">审核</el-button>
        <el-dropdown @command="handleCommand1">
          <el-button style="margin-left: 15px" type="primary">
           <span class="el-dropdown-link">
             订单处理<i class="el-icon-arrow-down el-icon--right"></i>
           </span>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 120px">
            <el-dropdown-item
             v-for="item in orderList"
             :key="item.id"
             :command="item.id"
            >{{ item.value }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleCommand2">
          <el-button style="margin-left: 15px" type="primary">
           <span class="el-dropdown-link">
             发货设置<i class="el-icon-arrow-down el-icon--right"></i>
           </span>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 120px">
            <el-dropdown-item
             v-for="item in shipSetList"
             :key="item.id"
             :command="item.id"
            >{{item.value}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleCommand3">
          <el-button style="margin-left: 15px" type="primary">
           <span class="el-dropdown-link">
             合并拆分<i class="el-icon-arrow-down el-icon--right"></i>
           </span>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 120px">
            <el-dropdown-item
             v-for="item in mergeSplitList"
             :key="item.id"
             :command="item.id"
            >{{item.value}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleCommand4">
          <el-button style="margin-left: 15px" type="primary">
           <span class="el-dropdown-link">
             客服处理<i class="el-icon-arrow-down el-icon--right"></i>
           </span>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 120px">
            <el-dropdown-item
             v-for="item in customerList"
             :key="item.id"
             :command="item.id"
            >{{item.value}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleCommand5">
          <el-button style="margin-left: 15px" type="primary">
           <span class="el-dropdown-link">
             更多操作<i class="el-icon-arrow-down el-icon--right"></i>
           </span>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="width: 120px">
            <el-dropdown-item
             v-for="item in operationList"
             :key="item.id"
             :command="item.id"
            >{{item.value}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 已取消 -->
      <div class="buttonGroup" v-show="activeName=='4'?true:false">
        <el-button @click="handleToProcessed">转待处理</el-button>
        <el-button @click="handleCustomerNote">客服备注</el-button>
        <el-button @click="handleMarkTag">标记标签</el-button>
      </div>
      <el-link 
       :underline="false" 
       style="margin: 15px 0 0 15px" 
       v-show="multipleSelection.length==0?false:true" 
      >已选{{multipleSelection.length}}条数据</el-link>
    </el-row>
    <el-row type="flex" justify="end">
      <tableDialog :tableList="tableData" @setTableHead="setTableHead" style="margin:10px 20px 10px 0"></tableDialog>
    </el-row>
    <!-- 列表区域 -->
    <div class="content">
     <el-table 
       border
       :data="tableList"
       style="width:100%; flex:1; overflow:auto"
       :selectable="selectable"
       @sort-change="sortTable"
       @selection-change="handleSelectionChange"
       header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
     >
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column label="系统单号" align="center" width="130">
        <template slot-scope="scope">
          <el-link :underline="false" @click="handleSystemNumber(scope.row)">{{scope.row.systemNumber}}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="平台单号" align="center" width="180">
        <template slot-scope="scope">
          <el-link :underline="false" @click="handleOrderNumber(scope.row)">{{scope.row.orderNumber}}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="商品" align="center" width="150">
        <template slot-scope="scope">
          <el-popover
             width="650"
             placement="right"
             trigger="hover"
             transition="fade-in-linear"
             close-delay="30"
            >
             <el-table 
              border 
              :data="scope.row.items"
              header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
             >
              <el-table-column label="商品信息" width="150px" align="center">
                <template slot-scope="scope">
                  <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
                    <div class="noPic">
                      <span v-if="!scope.row.url">暂无图片</span>
                      <img :src="scope.row.url" alt="" width="100%" height="100%">
                    </div>
                    <div style="text-align:left">
                      <p>ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
                      <p>MSKU:&nbsp;&nbsp;{{scope.row.msku}}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
              <el-table-column label="SKU" prop="sku" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="单价" prop="price" align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="数量" prop="count" align="center"></el-table-column>
              <el-table-column label="可用量" prop="availableCount" align="center"></el-table-column>
              <el-table-column label="商品备注" prop="" align="center"></el-table-column>
             </el-table>
             <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
               <div class="noPic">
                 <span v-if="!scope.row.items[0].url">暂无图片</span>
                 <div v-else v-for="(item,index) in scope.row.items" :key="index">
                   <img 
                    :src="item.url" 
                    alt="" 
                    style="width: 100%; heigth: 100%"
                   >
                 </div>
                <!-- <el-link
                 type='primary' 
                 class="el-icon-edit" 
                 style="margin-top: 20%; margin-left: 5%"
                 @click="handleGoods(scope.row.id,scope.row.tips)"
                ></el-link> -->
               </div>
             </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="SKU" align="center" width="160">
        <template slot-scope="scope">
            <el-popover
              width="680"
              placement="right"
              trigger="hover"
              transition="fade-in-linear"
              close-delay="30"
            >
              <el-table
                border
                :data="scope.row.items"
                header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
              >
                <el-table-column label="商品信息" align="center" width="180">
                  <template slot-scope="scope">
                   <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
                     <div class="noPic">
                      <span v-if="!scope.row.url">暂无图片</span>
                      <img :src="scope.row.url" alt="" width="100%" height="100%">
                     </div>
                     <div style="text-align:left">
                       <p>ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
                       <p>MSKU:&nbsp;&nbsp;{{scope.row.msku}}</p>
                     </div>
                   </div>
                  </template>
                </el-table-column>
                <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
                <el-table-column label="SKU" prop="sku" align="center" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="单价" prop="price" align="center"></el-table-column>
                <el-table-column label="数量" prop="count" align="center"></el-table-column>
                <el-table-column label="可用量" prop="availableCount" align="center"></el-table-column>
                <el-table-column label="商品备注" prop="remarks" align="center"></el-table-column>
              </el-table>
              <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
               <div>
                 <p style="margin-top: 5px; font-size: 15px" v-for="item in scope.row.items" :key="item.id">
                   <span v-if="item.sku">{{item.sku}}&nbsp;&nbsp; x{{item.count}}</span>
                   <span style="color: red" v-else-if="!item.sku">{{item.msku}}&nbsp;&nbsp; x{{item.count}}</span>
                 </p>
               </div>
              </div>
            </el-popover>
        </template>
      </el-table-column>
      <template v-for="column in columns">
        <el-table-column
          :key="column.prop"
          :label="column.name"
          :prop="column.prop"
          :sortable="column.sortable"
          :width="column.width"
          :align="column.align == null ? 'center' : column.align"
          :show-overflow-tooltip="column.tooltip == null ? false : column.tooltip"
        >
           <template slot-scope="scope">
             <el-link v-if="column.name=='SKU'">
               <p v-for="(item,index) in scope.row.items" :key="index">
                 <span v-if="index==0">{{item.sku}}</span>
               </p>
             </el-link>
             <span v-if="column.name=='状态'">
               <el-link :underline="false" v-if="scope.row.orderStatus =='待审核'" style="color: black">待审核</el-link>
               <el-link :underline="false" v-if="scope.row.orderStatus =='已发货'" style="color: green">已发货</el-link>
               <el-link :underline="false" v-if="scope.row.orderStatus =='待发货'" style="color: blue">待发货</el-link>
               <el-link :underline="false" v-if="scope.row.orderStatus =='已取消'" style="color: #DAA520">已取消</el-link>
               <p style="color: red; font-size: 14px">{{scope.row.dealStatus}}</p>
             </span>
             <span v-if="column.name=='客服备注'">
               <div style="display: flex; justify-content: start">  
                 <p>{{scope.row.csRemarks}}</p>
                 <el-link
                  type='primary' 
                  class="el-icon-edit" 
                  style="margin-left: 10%"
                  @click="handleTips(scope.row.id,scope.row.csRemarks)"
                 ></el-link>
               </div>
             </span>  
             <span v-if="column.name=='剩余发货时间'">
               <div style="display: flex; justify-content: center">
                 <p style="color: #FFA500">{{scope.row.remainShipTime}}</p>
               </div>
             </span>
             <p v-else>
               <span>{{scope.row[column.prop]}}</span>
             </p>
           </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" prop="" align="center" fixed="right">
        <template slot-scope="scope">
          <el-link type="primary" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-link>
        </template>
      </el-table-column>
     </el-table>
    </div>
    <!-- 分页区域 -->
    <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
     <el-pagination
       @size-change="handleSizeChange"
       @current-change="handleCurrentChange"
       layout="total, sizes, prev, pager, next, jumper"
       :page-sizes="[20, 30, 50, 100]"
       :total="total"
       :page-size="pageList.pageSize"
       :current-page="pageList.current"
       style="text-align: center; margin-top: 15px"
     ></el-pagination>
    </div>
    <!-- 客服备注 -->
    <el-dialog
      width="20%"
      title="客服备注"
      :visible.sync="customerVisible"
    >    
      <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="customerContent"></el-input>
      <el-row type="flex" justify="space-between" style="margin-top: 20px">
        <el-checkbox v-model="isChecked">替换原有备注</el-checkbox>
        <div>
          <el-button @click="customerVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirm">保 存</el-button>
        </div>
      </el-row>
    </el-dialog>
    <!-- 标记标签 -->
    <el-dialog
      width="30%"
      title="标记标签"
      :visible.sync="markTagsVisible"
    >
      <el-row type="flex" justify="start">
        <el-input 
         clearable 
         v-model="tagsKeyword"
         @clear="getTagsLabelList"
         placeholder="请输入搜索内容" 
         style="width: 30%; margin-right: 15px"
        ></el-input>
        <el-button icon="el-icon-search" type="primary" @click="getTagsLabelList">查询</el-button>
      </el-row>
      <el-row style="margin: 20px 0">
       <el-checkbox v-model="isAll" @change="handleCheckAllChange">全选</el-checkbox>
      </el-row>
      <el-checkbox-group v-model="checked">
        <el-row :gutter="10">
          <el-col v-for="(item,index) in tagsList" :key="item.id" :span="5">
            <el-checkbox @change="length" style="marginBottom:10px" :label="item.id">{{item.value}}</el-checkbox>
          </el-col>
        </el-row>
      </el-checkbox-group>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="mini"
        placeholder="请输入标签"
        style="width: 16%"
        @blur="handleInputConfirm"
      ></el-input>
      <el-link 
        v-else 
        size="small" 
        type="primary" 
        @click="showInput"
        :underline="false"
      >自定义标签</el-link>
      <span slot="footer">
        <el-button @click="markTagsVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleManageConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转待处理 -->
    <el-dialog
      width="30%"
      title="转待处理"
      :visible.sync="pendingVisible"
    >
     <el-row type="flex" justify="start">
       <el-input clearable @clear="getTagsDealList" style="width: 30%; margin-right: 15px" placeholder="请输入截单原因" v-model="TagsDealContent"></el-input>
       <el-button icon="el-icon-search" type="primary" @click="getTagsDealList">查询</el-button>
     </el-row>
      <el-row style="margin: 20px 0 15px">
        <span style="color: red">*</span><span>截单原因</span>
      </el-row>
      <el-radio-group v-model="isCheckList">
        <el-row :gutter="10">
          <el-col v-for="(item,index) in checkList" :key="index" :span="6">
            <el-radio @change="changeChecked" style="marginBottom:10px" :label="item" >{{item.value}}</el-radio>
          </el-col>
        </el-row>
      </el-radio-group>
      <el-row style="margin-top:15px">
        <el-input
          class="input-new-tag"
          v-if="input_Visible"
          v-model="input_Value"
          ref="keepTagInput"
          size="mini"
          placeholder="请输入标签"
          style="width: 16%"
          @blur="handleInputSave"
        ></el-input>
        <el-link 
          v-else 
          size="small" 
          type="primary" 
          @click="handleShowInput"
          :underline="false"
        ><i class="el-icon-circle-plus-outline"></i>
           自定义标签
        </el-link>
      </el-row>
      <span slot="footer">
        <el-button @click="pendingVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设定仓库 -->
    <el-dialog
      width="20%"
      title="设定仓库"
      :visible.sync="storeVisible"
    >
      <el-form :model="formShipments" label-width="100px">
        <el-form-item label="发货仓库">
          <el-select 
           clearable
           style="width: 60%"
           placeholder="请选择发货仓库"
           v-model="formShipments.storageId" 
          >
           <el-option
            v-for="item in storageList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
           ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="storeVisible=false">取消</el-button>
        <el-button type="primary" @click="handleSetStore">确定</el-button>
      </span>
    </el-dialog>
    <!-- 设定物流 -->
    <el-dialog
      width="20%"
      title="设定物流"
      :visible.sync="shipTrackWayVisible"
    >
      <el-form :model="formShipTrackWay" label-width="100px">
        <el-form-item label="物流方式">
          <el-select 
            clearable
            style="width: 60%"
            placeholder="请选择物流方式"
            v-model="formShipTrackWay.shipTrackWayId" 
          >
           <el-option
            v-for="item in supplyModeList"
            :key="item.id"
            :label="item.trackWay"
            :value="item.id"
           ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="shipTrackWayVisible = false">取消</el-button>
        <el-button type="primary" @click="handleShipTrackWay">确定</el-button>
      </span>
    </el-dialog>
    <!-- 订单拆分 -->
    <el-dialog
      width="40%"
      title="订单拆分"
      :visible.sync="orderSplitVisible"
    >
      <el-table 
        border
        :data="orderSpliList"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column label="商品信息" prop="" align="center">
          <template slot-scope="scope">
           <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
             <div class="noPic">
               <span v-if="!scope.row.url">暂无图片</span>
               <img v-else :src="scope.row.url" alt="" width="100%" height="100%">
             </div>
             <div style="text-align:left">
               <p>ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
               <p>SKU:&nbsp;&nbsp;{{scope.row.sku}}</p>
             </div>
           </div>
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
        <el-table-column label="SKU" prop="sku" align="center"></el-table-column>
        <el-table-column label="剩余拆分数量" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.count}}</span>
          </template>
        </el-table-column>
        <el-table-column label="拆分数量" align="center">
          <template slot-scope="scope">
            <el-input 
             v-model="scope.row.splitCount"
             :disabled="scope.row.count==0?true:false"
             @input="scope.row.splitCount=scope.row.count<=scope.row.splitCount?scope.row.count:scope.row.splitCount"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="end" style="margin-top: 30px">
       <span style="margin-top: 8px; margin-right: 15px">剩余未拆分的商品自动形成一个订单</span>
       <el-button @click="handleSplitPreview">生成拆分预览</el-button>
      </el-row>
      <el-row v-for="(item,index) in arrayList" :key="index">
        <h4 style="margin: 15px 0">拆分订单{{++index}}</h4>
        <el-table 
         border 
         :data="item"
         header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
        >
         <el-table-column label="商品信息" prop="" align="center"></el-table-column>
         <el-table-column label="商品名称" prop="productName" align="center"></el-table-column>
         <el-table-column label="SKU" prop="sku" align="center"></el-table-column>
         <el-table-column label="数量" prop="num" align="center"></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer">
        <el-button @click="orderSplitVisible=false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmSplit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 系统单号 -->
    <el-dialog
      width="70%"
      title="系统单号"
      :visible.sync="SystemVisible"
      :before-close="detailClose"
    >
      <div class="el-dialog-div">
        <el-row style="margin-bottom: 30px">
         <h3>系统单号{{103139976494625280}}</h3>
        </el-row>
        <!-- 步骤条 -->
        <el-row style="margin-left: 10%">  
          <el-steps :space="200" :active="progressNumber" finish-status="success">
            <el-step title="订购">
              <p slot="description">
               <span>{{systemNumberList.purchaseTime}}</span>
              </p>
            </el-step>
            <el-step title="付款">
              <p slot="description">
                <span>{{systemNumberList.paymentTime}}</span>
              </p>
            </el-step>
            <el-step title="审核">
              <p slot="description">
                <span>{{systemNumberList.auditTime}}</span>
              </p>
            </el-step>
            <el-step title="配货">
              <p slot="description">
                <span>{{systemNumberList.wmsPickTime}}</span>
              </p>
            </el-step>
            <el-step title="物流下单">
              <p slot="description">
                <span>{{systemNumberList.wmsOrderTime}}</span>
              </p>
            </el-step>
            <el-step title="待打单">
              <p slot="description"> 
                <span>{{systemNumberList.wmsDealTime}}</span>
              </p>
            </el-step>
            <el-step title="发货中">
              <p v-if="!systemNumberList.shipTime"></p>
            </el-step>
            <el-step title="已发货">
              <p v-if="systemNumberList.shipTime" slot="description">
                <span>{{systemNumberList.shipTime}}</span>
              </p>
            </el-step>
          </el-steps>
        </el-row>
        <!-- 基本信息 -->
        <el-row>
         <div>
            <h4 style="margin: 20px 0">基本信息</h4>
            <div class="look">
              <div class="fn">
                <div class="lable">平台</div>
                <div class="value">{{systemNumberList.orderPlatform}}</div>
              </div>
              <div class="fn">
                <div class="lable">店铺</div>
                <div class="value">{{systemNumberList.orderShop}}</div>
              </div>
              <div class="fn">
                <div class="lable">订单类型</div>
                <div class="value">{{systemNumberList.orderType}}</div>
              </div>
              <div class="fn">
                <div class="lable">买家姓名</div>
                <div class="value">{{systemNumberList.buyerName}}</div>
              </div>
              <div class="fn">
                <div class="lable">买家邮箱</div>
                <div class="value">{{systemNumberList.buyerMail}}</div>
              </div>
              <div class="fn">
                <div class="lable">客选物流</div>
                <div class="value">{{systemNumberList.customerTrack}}</div>
              </div>
              <div class="fn">
                <div class="lable">客付运费</div>
                <div class="value">{{systemNumberList.shipPrice}}</div>
              </div>
              <div class="fn">
                <div class="lable">买家留言</div>
                <div class="value">{{systemNumberList.remarks}}</div>
              </div>
            </div>
         </div>
        </el-row>
        <!-- 收货地址 -->
        <el-row>
         <div>
           <h4 style="margin: 20px 0">收货地址</h4>
           <div class="fn">
             <div class="lable">收件人</div>
             <div class="value">{{systemNumberList.shipName}}</div>
           </div>
           <div class="fn">
             <div class="lable">邮编</div>
             <div class="value">{{systemNumberList.shipPostcode}}</div>
           </div>
           <div class="fn">
             <div class="lable">电话</div>
             <div class="value">{{systemNumberList.shipTelephone}}</div>
           </div>
           <div class="fn">
             <div class="lable">国家/地区</div>
             <div class="value">{{systemNumberList.shipCountry}}</div>
           </div>
           <div class="fn">
             <div class="lable">省/州</div>
             <div class="value">{{systemNumberList.shipState}}</div>
           </div>
           <div class="fn">
             <div class="lable">城市</div>
             <div class="value">{{systemNumberList.shipCity}}</div>
           </div>
           <div class="fn">
             <div class="lable">县/区</div>
             <div class="value">{{systemNumberList.shipDistrict}}</div>
           </div>
           <div class="fn">
             <div class="lable">详细地址</div>
             <div class="value">{{systemNumberList.shipAddress}}</div>
           </div>
         </div>
        </el-row>
        <!-- 仓储物流 -->
        <el-row>
         <div>
           <h4 style="margin: 20px 0">仓储物流</h4>
           <div class="fn">
             <div class="lable">发货仓库</div>
             <div class="value">{{systemNumberList.shipStorageName}}</div>
           </div>
           <div class="fn">
             <div class="lable">物流方式</div>
             <div class="value">{{systemNumberList.shipTrackWayName}}</div>
           </div>
          <div class="fn">
            <div class="lable">COD订单</div>
            <div class="value">{{systemNumberList.cod}}</div>
          </div>
          <div class="fn">
            <div class="lable">跟踪号</div>
            <div class="value">{{systemNumberList.trackNumber}}</div>
          </div>
          <div class="fn">
            <div class="lable">预估运费</div>
            <div class="value">{{systemNumberList.shipPriceExpect}}</div>
          </div>
          <div class="fn">
            <div class="lable">包裹实重</div>
            <div class="value">{{systemNumberList.packageWeight}}</div>
          </div>
          <div class="fn">
            <div class="lable">包裹尺寸</div>
            <div class="value">{{systemNumberList.packageSize}}</div>
          </div>
         </div>
        </el-row>
        <!-- 商品信息 -->
        <div>
          <h4 style="margin: 20px 0">商品信息</h4>
          <el-table 
             border 
             :data="systemNumberList.items"
             header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
            >
              <el-table-column label="平台单号" prop="orderNumber" align="center"></el-table-column>
              <el-table-column label="商品信息" prop="" align="center">
                <template slot-scope="scope">
                  <el-popover
                   width="520"
                   placement="right"
                   trigger="hover"
                   transition="fade-in-linear"
                   close-delay="30"
                  >
                    <div style="display:flex">
                      <div class="picture">
                        <span v-if="!scope.row.url">暂无图片</span>
                        <img v-else :src="scope.row.url" alt="" width="100%" height="100%">
                      </div>
                      <div class="suspend" style="width:590px;font-size:14px">
                        <p class="infoText">{{scope.row.title}}</p>
                        <p style="margin-bottom:10px"><span><img style="width:20px;margin:0 6px 0 0"   :src="scope.row.url" alt=""/>子ASIN:{{scope.row.asin}}</span></p>
                        <p><span>SKU:{{scope.row.sku}}</span><span>品牌:{{scope.row.label}}</span></p>
                      </div>
                    </div>
                    <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
                      <div class="noPic">
                        <span v-if="!scope.row.url">暂无图片</span>
                        <img v-else :src="scope.row.url" alt="" width="100%" height="100%">
                      </div>
                      <div class="text-align:left">
                        <p>子ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
                        <p>MSKU:&nbsp;&nbsp;{{scope.row.msku}}</p>
                      </div>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="品名/SKU" prop="" align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{scope.row.productName}}</span><br/>
                    <span>{{scope.row.sku}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.price?scope.row.price:'-'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="数量" prop="count" align="center"></el-table-column>
              <el-table-column label="可用量" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.availableCount?scope.row.availableCount:'-'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="商品备注" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.remarks?scope.row.remarks:'-'}}</span>
                </template>
              </el-table-column>
          </el-table>
        </div>
        <!-- 客服备注 -->
        <div>
          <h4 style="margin: 20px 0">客服备注</h4>
          <span v-if="!systemNumberList.csRemarks">暂无客服备注</span>
          <el-input
            v-else
            :rows="2"
            type="textarea"
            placeholder="请输入客服备注"
            v-model="systemNumberList.csRemarks">
          </el-input>
        </div>
        <!-- 操作日志 -->
        <div>
          <h4 style="margin: 20px 0">操作日志</h4>
          <el-table 
           border 
           :data="systemNumberList.operationResList"
           header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
          >
            <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>
            <el-table-column label="操作人" prop="createBy" align="center"></el-table-column>
            <el-table-column label="操作" prop="type" align="center"></el-table-column>
            <el-table-column label="详情" prop="content" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="SystemVisible = false">取 消</el-button>
        <el-button type="primary" @click="SystemVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 订单编辑 -->
    <el-dialog
      width="70%"
      title="订单编辑"
      :visible.sync="editOrderVisible"
    >
      <el-row style="margin-bottom: 30px">
        <h3>系统单号{{23442345645}}</h3>
      </el-row>
      <!-- 步骤条 -->
      <el-row style="margin-left: 5%">
        <el-steps :space="200" :active="stepNumber" finish-status="success">
          <el-step title="订购">
            <p slot="description">
              {{orderEditList.purchaseTime}}
            </p>
          </el-step>
          <el-step title="付款">
            <p slot="description">
              {{orderEditList.paymentTime}}
            </p>
          </el-step>
          <el-step title="审核">
            <p slot="description">
              {{orderEditList.auditTime}}
            </p>
          </el-step>
          <el-step title="配货">
            <p slot="description">
              {{orderEditList.wmsPickTime}}
            </p>
          </el-step>
          <el-step title="物流下单">
            <p slot="description">
              {{orderEditList.wmsOrderTime}}
            </p>
          </el-step>
          <el-step title="待打单">
            <p slot="description">
              {{orderEditList.wmsDealTime}}
            </p>
          </el-step>
          <el-step v-if="!orderEditList.shipTime" title="发货中">
            <p slot="description">
              {{orderEditList.shipTime}}
            </p>
          </el-step>
          <el-step v-else-if="orderEditList.shipTime" title="已发货">
            <p slot="description">
              {{orderEditList.shipTime}}
            </p>
          </el-step>
        </el-steps>
      </el-row>
      <!-- 基本信息 -->
      <el-row :gutter="20">
        <div>
          <h4 style="margin: 20px 0">基本信息</h4>  
          <div class="look">
           <div class="fn">
             <div class="lable">平台</div>
             <div class="value">{{orderEditList.orderPlatform}}</div>
           </div>
           <div class="fn">
             <div class="lable">店铺</div>
             <div class="value">{{orderEditList.orderShop}}</div>
           </div>
           <div class="fn">
             <div class="lable">订单类型</div>
             <div class="value">{{orderEditList.orderType}}</div>
           </div>
           <div class="fn">
            <div class="lable">买家姓名</div>
            <div class="value">{{orderEditList.buyerName}}</div>
           </div>
           <div class="fn">
             <div class="lable">买家邮箱</div>
             <div class="value">{{orderEditList.buyerMail}}</div>
           </div>
           <div class="fn">
             <div class="lable">客选物流</div>
             <div class="value">{{orderEditList.customerTrack}}</div>
           </div>
           <div class="fn">
             <div class="lable">客付运费</div>
             <div class="value">{{orderEditList.shipPrice}}</div>
           </div>
           <div class="fn">
             <div class="lable">买家留言</div> 
             <div class="value">{{orderEditList.remarks}}</div>
           </div>
          </div>
        </div>
      </el-row>
      <!-- 收货地址 -->
      <el-row>
        <h4 style="margin: 20px 0">收货地址</h4>
        <el-form :inline="true" :model="formInline" label-width="90px" label-position="right">
          <el-row type="flex" justify="start">
              <el-col :span="6">
                <el-form-item label="收件人：">
                  <el-input clearable v-model="formInline.receiver" style="width: 100%" placeholder="收件人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="邮编：">
                  <el-input clearable v-model="formInline.postCode" style="width: 100%" placeholder="邮编"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
               <el-form-item label="电话：">
                 <el-input clearable v-model="formInline.telephone" style="width: 100%" placeholder="请输入"></el-input>
               </el-form-item>
              </el-col>
              <el-col :span="6">
               <el-form-item label="国家/地区：">
                <el-select 
                 clearable
                 filterable
                 style="width: 90%" 
                 v-model="formInline.countryArea" 
                 placeholder="请选择国家/地区"
                >
                  <el-option
                   v-for="item in countryList"
                   :key="item.id"
                   :label="item.nameCn"
                   :value="item.iso31661"
                  ></el-option>
                </el-select>
               </el-form-item>
              </el-col>
          </el-row>
          <el-row type="flex" justify="start">
              <el-col :span="6">
                <el-form-item label="省/州：">
                  <el-input v-model="formInline.province" style="width: 100%" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="城市：">
                  <el-input v-model="formInline.city" style="width: 100%" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="区/县：">
                  <el-input v-model="formInline.country" style="width: 100%" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="详细地址：">
                  <el-input v-model="formInline.address" style="width: 100%" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
        </el-form>
      </el-row>
      <!-- 仓储物流 -->
      <el-row>
        <h4 style="margin: 20px 0">仓储物流</h4>
        <el-form :inline="true" :model="formStorage" label-width="100px" label-position="right">
           <el-row type="flex" justify="start">
            <el-col :span="6">
              <el-form-item label="发货仓库">
                <el-select clearable placeholder="请选择发货仓库" v-model="formStorage.storageId">
                  <el-option
                   v-for="item in shipmentGoods"
                   :key="item.id"
                   :label="item.value"
                   :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="物流方式"> 
                <el-select clearable placeholder="请选择物流方式" v-model="formStorage.shipTrackWay">
                  <el-option
                    v-for="item in shipTrackWayList"
                    :key="item.id"
                    :label="item.trackWay"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="COD订单">
                <el-select clearable v-model="formStorage.codOrder">
                 <el-option label="是" value="0"></el-option>
                 <el-option label="否" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
               <span slot="label">
                 <span style="font-size: 14px">运单号</span>
                 <el-tooltip placement="top">
                   <div slot="content">
                     订单对应【销售出库单】中的运单号
                   </div>
                   <i class="el-icon-question"></i>
                 </el-tooltip>
               </span>
               {{orderEditList.trackNumber}}
              </el-form-item>
            </el-col>
           </el-row>
           <el-row type="flex" justify="start">
             <el-col :span="6">
              <el-form-item label="估算重量">
                {{orderEditList.packageWeight}}
              </el-form-item>
             </el-col>
             <el-col :span="6">
              <el-form-item label="包裹尺寸">
                {{orderEditList.packageSize}}
              </el-form-item>
             </el-col>
           </el-row>
        </el-form>
      </el-row>
      <!-- 商品信息 -->
      <el-row>
        <h4>商品信息</h4>
        <el-row style="margin: 20px 0">
          <el-button type="primary" icon="el-icon-plus" @click="handleAddGoods(0)">添加商品</el-button>
        </el-row>
        <el-table 
           border 
           :data="goodsInforList"
           header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
          >
            <el-table-column label="平台单号" prop="orderNumber" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="商品信息" align="cenetr">
              <template slot-scope="scope">
                <el-popover
                 width="520"
                 placement="right"
                 trigger="hover"
                 transition="fade-in-linear"
                 close-delay="30"
                >
                  <div style="display:flex">
                    <div class="picture">
                      <span v-if="!scope.row.url">暂无图片</span>
                      <img v-else :src="scope.row.url" alt="" width="100%" height="100%">
                    </div>
                    <div class="suspend" style="width:590px;font-size:14px">
                      <p class="infoText">{{scope.row.title}}</p>
                      <p style="margin-bottom:10px"><span><img style="width:20px;margin:0 6px 0 0"  :src="scope.row.url" alt=""/>子ASIN:{{scope.row.asin}}</span></p>
                      <p><span>SKU:{{scope.row.sku}}</span><span>品牌:{{scope.row.label}}</span></p>
                    </div>
                  </div>
                  <div class="goodsInfo" slot="reference" style="display:flex;align-items:center">
                    <div class="noPic">
                      <span v-if="!scope.row.url">暂无图片</span>
                      <img v-else :src="scope.row.url" alt="" width="100%" height="100%">
                    </div>
                    <div class="text-align:left">
                      <p>子ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
                      <p>SKU:&nbsp;&nbsp;{{scope.row.sku}}</p>
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="品名/SKU" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div>
                  <span>{{scope.row.productName}}</span><br/>
                  <span>{{scope.row.sku}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="单价" align="center">
              <templat slot-scope="scope">
                <span>{{scope.row.price?scope.row.price:'-'}}</span>
              </templat>
            </el-table-column>
            <el-table-column label="数量" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.count"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="可用量" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.availableCount?scope.row.availableCount:'-'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="商品备注" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.remarks?scope.row.remarks:'-'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link type="primary" v-if="!scope.row.productName&&!scope.row.sku" style="margin-right: 10px" @click="handleMatches(1, scope.$index)">配对</el-link>
                <el-link type="primary" v-else-if="scope.row.productName&&scope.row.sku" style="margin-right: 10px" @click="handleChange(2, scope.$index)">换货</el-link>
                <el-link type="danger" @click="handleRemove(scope.$index)">移除</el-link>
              </template>
            </el-table-column>
        </el-table>
      </el-row>
      <!-- 客服备注 -->
      <el-row>
        <h4 style="margin: 20px 0">客服备注</h4>
        <el-input
          :rows="3"
          type="textarea"
          placeholder="请输入内容"
          v-model="formStorage.customerTips"
        ></el-input>
      </el-row>
      <!-- 操作日志 -->
      <el-row>
        <h4 style="margin: 20px 0">操作日志</h4>
        <el-table 
         border 
         :data="operaTableList"
         header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
        >
          <el-table-column label="操作时间" prop="createTime" align="center"></el-table-column>
          <el-table-column label="操作人" prop="createBy" align="center"></el-table-column>
          <el-table-column label="操作" prop="type" align="center"></el-table-column>
          <el-table-column label="详情" prop="content" align="center"></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer">
        <el-button @click="editOrderVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmEdit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 配对添加商品 -->
    <el-dialog
      width="40%"
      title="添加商品"
      :visible.sync="addProductVisible"
    >
      <el-row type="flex" justify="start" style="margin-bottom: 20px">
        <el-input 
         clearable
         @clear="getProductList()"
         placeholder="请输入品名/SKU" 
         v-model="productList.keyword"
         style="margin-right: 15px; width: 20%" 
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="getProductList">查询</el-button>
      </el-row>
      <el-table
        border
        row-key="id"
        :data="productDataList"
        @selection-change="handleSelectionProduct"
        header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column type="selection" :reserve-selection="true" :selectable="checkBoxDisable" width="60" align="center"></el-table-column>
        <el-table-column label="图片" prop="productImagePath" align="center"></el-table-column>
        <el-table-column label="品名" prop="productName" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="SKU" prop="commoditySku" align="center"></el-table-column>
        <el-table-column label="分类" prop="productTypeName" align="center"></el-table-column>
        <el-table-column label="品牌" prop="-" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
         <!-- :disabled="type==0?true:false" -->
         <template slot-scope="scope"> 
           <el-link 
            style="color: #4169E1"
            v-show="type==0?true:false"
           >选择</el-link>
           <el-link
            style="color: #4169E1"
            v-show="type==1?true:false"
            @click.native="handleChoose(scope.$index,scope.row)"
           >选择</el-link>
           <el-link
            style="color: #4169E1"
            v-show="type==2?true:false"
            @click.native="handleExchange(scope.$index,scope.row)"
           >选择</el-link>
         </template>
        </el-table-column>
      </el-table>
      <!-- 底部分页 -->
      <el-pagination
        @size-change="handleSizeProduct"
        @current-change="handleCurrentProduct"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[20, 30, 50, 100]"
        :total="productTotal"
        :page-size="productList.pageSize"
        :current-page="productList.current"
        style="text-align: center; margin-top: 15px"
      ></el-pagination>
      <span slot="footer">
        <el-button @click="addProductVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSelectGood">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 审核订单失败 -->
    <el-dialog
      center
      width="23%"
      title="全部订单审核失败"
      :visible.sync="auditFailVisible"
    >
      <el-row style="text-align:center; margin-bottom: 30px">
        <span style="color: red">{{auditFailList.length}}个订单审核失败</span>
      </el-row>
      <el-table
       border
       :data="auditFailList"
       header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column label="系统单号" prop="id" align="center"></el-table-column>
        <el-table-column label="失败原因" prop="value" align="center"></el-table-column>
      </el-table>
      <span slot="footer">
        <el-button type="primary" @click="auditFailVisible = false">知道了</el-button>
      </span>
    </el-dialog>
    <!-- 批量编辑 -->
    <el-dialog
      width="23%"
      title="批量编辑"
      :visible.sync="handleEditVisible"
    >
      <el-alert title="只有被勾选的内容才会提交" type="success" :closable="false"></el-alert>
      <el-row style="margin: 30px 0">
        <el-col :span="4">收货地址</el-col>
        <el-col :span="12">
          <el-checkbox v-model="checkTypeList" label="city" style="margin-bottom: 10px">
            城市为空, 使用省/州信息进行填充
          </el-checkbox>
          <el-checkbox v-model="checkTypeList" label="region">
            省/州为空, 使用城市信息进行填充
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">商品信息</el-col>
        <el-col :span="12">
          <el-checkbox v-model="checkTypeList" label="sku">重新获取SKU配对数据</el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button @click="handleEditVisible=false">取 消</el-button>
        <el-button type="primary" @click="handleBatchEdit">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 拆分订单 -->
    <el-dialog
      width="23%"
      title="拆分订单"
      :visible.sync="splitResultVisible"
    >
      <el-table 
       border 
       :data="splitOrderList"
       header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
      >
        <el-table-column label="系统单号" prop="id" align="center"></el-table-column>
        <el-table-column label="商品状态" prop="value" align="center"></el-table-column>
      </el-table>
      <span style="text-align: center; margin-top: 10px">
        <el-button type="primary" @click="splitResultVisible=false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
   pageInit,  // 初始化信息
   listPage,  // 查询(分页)
   handleDetail,  // 查看详情
   handleUpdateInit,  // 编辑初始化
   editFbm,        // 确认编辑
   handleOperate,  // 操作事件
   getTagsDeal,    // 状态标签
   getTagsLabel,    // 订单标签
   handleFilteAudit, // 筛选审核
   handleSetLabel,  // 新增状态标签
}from '@/api/shipManage/shipmentOrder.js'
import {
     getProductListPage,  // 商品查询 (分页)
}from '@/api/procurementManage/procurementPlan.js'
import tableDialog from '@/components/tableDialog.vue'
export default {
   components: {
     tableDialog
   },
  data() {
    return {
     total: 0,
     productTotal: 0,
     type: 0,  // type: 0 表示添加  1 表示配对  2 表示换货
     optionIndex: "",
     optionIndex2: "",
     loading: false,
     customerVisible: false,
     isChecked: false,  // 替换原有备注
     isCheckList: "",
     markTagsVisible: false,
     pendingVisible: false,
     storeVisible: false,
     SystemVisible: false,
     orderSplitVisible: false,  // 订单分割
     handleEditVisible: false,   // 批量编辑
     activeName: "0",
     inputContent: "",
     selectContent: "systemNumber",
     arrayList: [],
     customerContent: "",   // 客服备注
     TagsDealContent: "",
     tagsKeyword: "",
     isAll: "",
     tabName: "",
     inputVisible: false,
     input_Visible: false,
     addProductVisible: false,
     input_Value: "",
     inputValue: "",
     checked: [],
     tagsList: [],
     checkList: [],
     countryList: [],      // 国家集合
     shipmentGoods: [],    // 发货仓库
     shipTrackWayList: [], // 物流方式集合
     selectList: [
       {id: 'systemNumber', value: "系统单号" },
       {id: 'orderNumber', value: "平台单号" },
       {id: 'asin', value: "ASIN" },
       {id: 'msku', value: "MSKU" },
       {id: 'sku', value: "SKU" },
       {id: 'buyerName', value: "买家姓名" },
       {id: 'shipTrackNumber', value: "追踪号" },
     ],
     formInline: {    // 收货地址
       receiver: "",     // 收件人
       postCode: "",     // 邮编
       telephone: "",    // 电话
       countryArea: "",  // 国家/地区
       province: "",     // 省/州
       city: "",         // 城市
       country: "",      // 县/区
       address: "",      // 详细地址
     },
     formStorage: {   // 仓储物流
       storageId: "",
       shipTrackWay: "",
       codOrder: "",
       customerTips: "", // 客服备注
     },
     productList: {
       keyword: "",
       current: 1,
       pageSize: 20
     },
     shopList: [],        // 店铺列表
     orderTagList: [],    // 订单标签列表
     orderStateList: [],  // 订单状态列表
     storageList: [],     // 发货仓库
     platformList: [],
     providerList: [],    // 供应商
     supplyModeList: [],  // 供应方式
     operaTableList: [],  // 操作日志
     goodsInforList: [],  // 商品信息
     productDataList: [],     //  商品集合
     pageList: {
       profileId: "",  // 店铺Id和这站点Id
       storageId: [],  // 发货仓库
       platform: "",   // 平台
       provider: [],   // 物流商
       logisticsMode: [],  // 物流方式
       orderTags: [],   // 订单标签
       orderState: [],  // 订单状态
       dateType: "0",   // 时间类型
       current: 1,
       pageSize: 20,
       startDate: "",  // 开始时间
       endDate: ""     // 结束时间
     },
     chooseDate: '',  // 用户选择的时间
     orderList: [],      // 订单处理
     shipSetList: [],    // 发货设置
     mergeSplitList: [], // 合并拆分
     customerList: [],   // 客服处理
     operationList: [],  // 更多操作
     buttonGroupList: [],
     tableList: [],
     columns: [],
     multipleSelection: [],
     tableData: [
       { name: "状态", sortable: false, align:"center", width:'150' },
       { name: "标签", prop: "labels", sortable: false, align:"center", width:'150', tooltip: "true" },
       { name: "客服备注", prop: "csRemarks", sortable: false, width:'130' },
       { name: "目的地", prop: "shipCounty", sortable: false },
       { name: "发货仓库", prop: "shipStorageName", sortable: false, width:'150' },
       { name: "物流方式", prop: "shipTrackTypeName", sortable: false, width:'130' },
       { name: "剩余发货时间", sortable: true, width:'150', align:"center", },
       { name: "订单总金额", prop: "totalPrice", sortable: false, width:'150' },
       { name: "客付运费", prop: "shipPrice", sortable: false, },
       { name: "买家姓名", prop: "buyerName", sortable: false, width:'150' },
       { name: "客选物流", prop: "", sortable: false },
       { name: "买家留言", prop: "", sortable: false },
       { name: "店铺", prop: "shopName", sortable: false, tooltip: "true", width:'150'},
       { name: "国家", prop: "orderCountry", sortable: false },
       { name: "跟踪号", prop: "", sortable: false },
       { name: "标发时间", prop: "uploadTime", sortable: false },
       { name: "订购时间", prop: "purchaseTime", sortable: true, tooltip: "true", width:'170' },
       { name: "付款时间", prop: "paymentTime", sortable: false, tooltip: "true", width:'170' },
       { name: "发货时限", prop: "", sortable: true, width:'150' },
       { name: "发货时间", prop: "shipTime", sortable: false, width:'150' },
       { name: "取消订单时间", prop: "cancelTime", sortable: false, width:'150' },
       { name: "订单类型", prop: "orderType", sortable: false, width:'150' },
     ],
     formShipments: { storageId: "" }, // 发货仓库
     formShipTrackWay: { shipTrackWayId: "" },  // 物流方式
     waitDealCount: 0,  
     waitSendCount: 0, 
     systemNumberList: {},
     editOrderVisible: false,
     orderEditList: {},   // 编辑初始化数据
     multipleSelectionRow: [],
     editId: "",  // 编辑Id
     auditFailVisible: false,
     auditFailList: [],  // 审核失败集合
     shipTrackWayVisible: false,  // 设定物流
     orderSpliList: [],
     statusLabelId: "",
     checkTypeList: [],
     splitResultVisible: false,
     splitOrderList: [],  // 拆分订单
    }
  },
  created() {
    this.columns = this.tableData;
    this.getPageInit();
  },
  watch: {
    chooseDate(value) {
      if(value) {
        this.pageList.startDate = value[0];
        this.pageList.endDate = value[1];
      }else {
        this.pageList.startDate = "";
        this.pageList.endDate = "";
      }
    },
    $route(to){
      if(to.query.orderId){
        this.handleSystemNumber({id:to.query.orderId})
      }
    }
  },
  computed: {
    stepNumber() {
      switch(this.orderEditList.statusNumber) {
        case 0:
        return 1
        case 1:
        return 2
        case 2:
        return 3
        case 3:
        return 4
        case 4:
        return 5
        case 5:
        return 6
        case 6:
        return 7
        case 7:
        return 8
      }
    },
    progressNumber() {
      switch(this.systemNumberList.statusNumber) {
        case 1:
        return 1
        case 2:
        return 2
        case 3:
        return 3
        case 4:
        return 4
        case 5:
        return 5
      }
    }
  },
  mounted() {},
  methods: {
    getPageInit() {
      pageInit()
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.shopList = res.data.data.shopList;          // 店铺列表
          this.orderStateList = res.data.data.statusList;  // 订单状态
          this.storageList = res.data.data.storageList;    // 仓库列表
          this.orderTagList = res.data.data.labList;       // 订单标签
          this.platformList = res.data.data.platformList;  // 平台列表
          this.orderList = res.data.data.waitMap.orderDeal;      // 处理订单
          this.shipSetList = res.data.data.waitMap.sendSetting;  // 发货设置
          this.mergeSplitList = res.data.data.waitMap.mergeAndSplit;  // 合并拆分
          this.providerList = res.data.data.shipTrackList;       // 物流商
          this.supplyModeList = res.data.data.shipTrackWayList;  // 物流方式
          this.customerList = res.data.data.waitMap.csDeal;      // 客服处理
          this.operationList = res.data.data.waitMap.other;      // 更多操作
          res.data.data.shopList.forEach(ele => {
            if(ele.isSelected == 1) {
              this.pageList.profileId = ele.id+'-'+ele.marketPlaceId;
              this.search(this.pageList.profileId, 0)   // 初始化查询
            }
          });
        }
      })
    },
    handleClick(tab) {
      this.tabName = tab.name;
      if(tab.name == '0') {     // 全部
        this.search(this.pageList.profileId, 0);
      }else if(tab.name == '1') {   // 待处理
        this.search(this.pageList.profileId, 1);
      }else if(tab.name == '2') {   // 待发货
        this.search(this.pageList.profileId, 2);
      }else if(tab.name == '3') {   // 已发货
        this.search(this.pageList.profileId, 3);
      }else if(tab.name == '4') {   // 已取消
        this.search(this.pageList.profileId, 4);
      }
    },
    detailClose(){
      this.systemNumberList = {};  // 清空旧数据
      this.SystemVisible=false
      this.$router.push('/shipmentOrder')
    },
    // 系统单号详情
    handleSystemNumber(row) {
      handleDetail(row.id)
      .then(res=> {
        this.systemNumberList = {};  // 清空旧数据
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.systemNumberList = res.data.data;
        }
      })
      this.SystemVisible = true;
    },
    search(profileId, tabName, statusLabelId) {
      console.log(this.statusLabelId,statusLabelId);
      if(this.statusLabelId==statusLabelId){
        this.statusLabelId=''
      }else{
        this.statusLabelId = statusLabelId;
      }
      // if(this.statusLabelId=='')
      this.loading = true;
      // 组装请求参数 
      let TempData = "";
      if(this.selectContent == "" || this.inputContent == "") {
        TempData = null;
      }else if(this.selectContent == "systemNumber") {
        TempData = { systemNumber: this.inputContent }
      }else if(this.selectContent == "orderNumber") {
        TempData = { orderNumber: this.inputContent }
      }else if(this.selectContent == "asin") {
        TempData = { asin: this.inputContent }
      }else if(this.selectContent == "msku") {
        TempData = { msku: this.inputContent }
      }else if(this.selectContent == "sku") {
        TempData = { sku: this.inputContent }
      }else if(this.selectContent == "buyerName") {
        TempData = { buyerName: this.inputContent }
      }else if(this.selectContent == "shipTrackNumber") {
        TempData = { shipTrackNumber: this.inputContent }
      }
      let params = {
        topStatus: tabName,     // 根据状态查询
        current: this.pageList.current,
        pageSize: this.pageList.pageSize,
        shopMarketplaceId: profileId,                  // 店铺/站点Id
        storageId: this.pageList.storageId.join(','),  // 仓库Id
        platformId: this.pageList.platform,            // 平台id
        trackCompanyId: this.pageList.provider.join(','),  // 物流商
        trackWayId: this.pageList.logisticsMode.join(','),   // 物流方式
        label: this.pageList.orderTags.join(','),            // 订单标签
        statusLabel: this.statusLabelId,     // 待处理标签/子状态
        orderStatus: this.pageList.orderState.join(','),    // 订单类型
        dateType: this.pageList.dateType,                    // 日期类型
        startDate: this.pageList.startDate,
        endDate: this.pageList.endDate,
      }
      console.log(params);
      listPage({...TempData, ...params,})
      .then(res=> {
        this.loading = false;
        this.tableList = [];
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.tableList = res.data.values;
          this.total = res.data.pageInfo.total; 
          this.buttonGroupList = res.data.mapInfo.dealStatus;
          this.waitDealCount = res.data.mapInfo.waitDealCount;
          this.waitSendCount = res.data.mapInfo.waitSendCount; 
        }
        if(this.$route.query.orderId){
          this.handleSystemNumber({id:this.$route.query.orderId})
        }
      })  
    },
    handleSizeChange(newSize) {
      this.pageList.pageSize = newSize;
      this.search(this.pageList.profileId, this.tabName);
    },
    handleCurrentChange(newCurrent) {
      this.pageList.current = newCurrent;
      this.search(this.pageList.profileId, this.tabName);
    },
    // 编辑商品
    handleGoods() {},
    reset() {    // 重置事件
     this.pageList.storageId = [];
     this.pageList.platform = [];
     this.pageList.provider = [];
     this.pageList.logisticsMode = []; // 物流方式
     this.pageList.orderTags = [];     // 订单标签
     this.pageList.orderState = [];
     this.pageList.orderTags = "";
     this.pageList.dateType = "";
     this.inputContent = "";
     this.selectContent = "";
    },
    setTableHead(list) {
      this.columns = list;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 客服备注
    handleCustomerTips() {
      if(!this.multipleSelection.length) {
        this.$message.error('请选择要操作订单');
        return false;
      }
      this.customerContent = "";
      this.customerVisible = true;
    },
    getTagsDealList() {
      let params = {
        keyword: this.TagsDealContent
      }
      getTagsDeal(params)
       .then(res=> {
         this.checkList = [];
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.checkList = res.data.data;
         }
       })
    },
    // 转待处理
    handleToProcessed() {
      if(!this.multipleSelection.length) {
        this.$message.error('请选择要操作订单');
        return false;
      } 
      this.TagsDealContent = "";
      this.getTagsDealList();
      this.pendingVisible = true;
    },
    // 已取消(客服备注)
    handleCustomerNote() {
      if(!this.multipleSelection.length) {
        this.$message.warning('请选择要操作订单');
        return false;
      }
      this.customerVisible = true;
    },
    checkGroup(shopId, marketPlaceId) {
      this.pageList.profileId = shopId+'-'+marketPlaceId;
      this.search(this.pageList.profileId);
    },
    // 编辑初始化
    handleEdit(row) {
      this.editId = row.id;
      handleUpdateInit(row.id)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.shipmentGoods = res.data.data.storageList;      // 发货仓库
          this.shipTrackWayList = res.data.data.trackWayList;  // 物流方式
          this.countryList = res.data.data.countryList;        // 国家/地区
          this.orderEditList = res.data.data.detail;   
          this.goodsInforList = res.data.data.detail.items;    // 商品信息
          this.operaTableList = res.data.data.detail.operationResList;   // 操作记录
          this.formInline.receiver = res.data.data.detail.shipName;      // 收件人
          this.formInline.postCode = res.data.data.detail.shipPostcode;  // 邮编
          this.formInline.telephone = res.data.data.detail.shipTelephone;  // 电话
          this.formInline.countryArea = res.data.data.detail.shipCountry;  // 国家地区
          this.formInline.province = res.data.data.detail.shipState;    // 省/州
          this.formInline.city = res.data.data.detail.shipCity;         // 城市
          this.formInline.country = res.data.data.detail.shipDistrict;  // 县
          this.formInline.address = res.data.data.detail.shipAddress;   // 详细地址
          this.formStorage.storageId = res.data.data.detail.shipStorageId;  // 发货仓库
          this.formStorage.shipTrackWay = res.data.data.detail.shipTrackWayId;  // 物流方式
          this.formStorage.codOrder = (res.data.data.detail.cod).toString();  // COD 订单
          this.formStorage.customerTips = res.data.data.detail.csRemarks;  // 测试数据
        }
      })
      this.editOrderVisible = true;
    },
    // 确认编辑
    handleConfirmEdit() {
      let items = [];
      this.goodsInforList.forEach(item=> {
        console.log(item);
        let object = {
          count: parseInt(item.count),
          itemId: item.id,
          sku: item.asin,
          skuId: ""
        }
        items.push(object);
      })
      let data = {
        id: this.editId,
        items: items,
        cod: parseInt(this.formStorage.codOrder),
        shipAddress: this.formInline.address,
        shipCity: this.formInline.city,
        shipCountry: this.formInline.countryArea,  // 国家/地区
        shipDistrict: this.formInline.country,      // 国家
        shipName: this.formInline.receiver,        // 收件人
        shipPostcode: this.formInline.postCode,    // 邮编
        shipState: this.formInline.province,       // 省/州
        shipStorageId: this.formStorage.storageId, // 仓库Id
        shipTelephone: this.formInline.telephone,  // 电话
        shipTrackWayId: this.formStorage.shipTrackWay, // 物流方式
        csRemarks: this.formStorage.customerTips      // 客服备注
      }
      editFbm(data)
      .then(res=> {
        console.log(res);
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);  // 提示
          this.editOrderVisible = false;
          this.search(this.pageList.profileId, this.tabName);
        }
      })
    },
    getTagsLabelList() {
      let params = {
        keyword: this.tagsKeyword
      }
      getTagsLabel(params)
      .then(res=> {
        this.tagsList = [];
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.tagsList = res.data.data;
        }
      })
    },
    // 标记标签
    handleMarkTag() {
      this.getTagsLabelList();
      this.markTagsVisible = true;
    },
    // 全选
    handleCheckAllChange() {
      if(this.isAll){
        this.checked = this.tagsList;
      }else {
        this.checked = [];
      }
    },
    length(){
      if(this.checked.length == this.tagsList.length){
        this.isAll = true;
      }else{
        this.isAll = false;
      }
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      if(!this.inputValue.trim()) {
        this.$message.error('标签名称不能为空');
        return false;
      }
      let inputValue = this.inputValue.trim();
      const test = (ele) => {
        return ele == inputValue;
      };
      let tagsList2 = this.tagsList.map(f=> f.value);
      let flag = tagsList2.some(test);
      if(flag) {
        this.$message.error('标签已存在, 禁止新增');
        this.inputValue = "";   // 数据清空
        return false;
      }
      let params = {
        name: inputValue,
        type: 1
      }
      handleSetLabel(params)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
          this.getTagsLabelList();    // 查询订单标签
        }
      })
      this.inputVisible = false;
      this.inputValue = '';
    },
    // 审核事件
    handleCheck() {
      if(!this.multipleSelection.length) {
        this.$message.error('请选择要操作订单');
        return false;
      }
      let ids = this.multipleSelection.map(v=> v.id);
      this.$confirm(`共${this.multipleSelection.length}个订单, 确认审核发货吗?`, '审核', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          ids: ids,
          index: 0,      // 审核
          type: "audit", 
        }
        handleOperate(data)
        .then(res=> {
          this.auditFailList = [];
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.auditFailList = res.data.data;
            this.auditFailVisible = true;
          }
        })
      }).catch(() => {});
    },
    // 订单处理
    handleCommand1(command) {
      if(command == 0) {         // 规则审核
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;
        }
        let ids = this.multipleSelection.map(s=> s.id);
        this.$confirm("订单将会重新执行订单规则, 是否确认规则审核?", '规则审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            ids: ids,
            index: 1,     // 规则审核
            type: "audit"   
          }
          handleOperate(data)
          .then(res=> {
            console.log(res);
            this.auditFailList = [];
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.auditFailList = res.data.data;
              this.auditFailVisible = true;   // 弹框弹出
            }
          })
        }).catch(() => {});
      }else if(command == 1) {   // 筛选审核
        this.$confirm("执行当前搜索条件下的所有数据, 只有待审核状态的订单支持审核,会过滤已排队在审核中的订单", '筛选审核', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 组装请求参数 
          let TempData = "";
          if(this.selectContent == "" || this.inputContent == "") {
            TempData = null;
          }else if(this.selectContent == "systemNumber") {
            TempData = { systemNumber: this.inputContent }
          }else if(this.selectContent == "orderNumber") {
            TempData = { orderNumber: this.inputContent }
          }else if(this.selectContent == "asin") {
            TempData = { asin: this.inputContent }
          }else if(this.selectContent == "msku") {
            TempData = { msku: this.inputContent }
          }else if(this.selectContent == "sku") {
            TempData = { sku: this.inputContent }
          }else if(this.selectContent == "buyerName") {
            TempData = { buyerName: this.inputContent }
          }else if(this.selectContent == "shipTrackNumber") {
            TempData = { shipTrackNumber: this.inputContent }
          }
          let params = {
            shopMarketplaceId: this.pageList.profileId,
            platformId: this.pageList.platform,
            storageId: this.pageList.storageId.join(','),
            trackCompanyId: this.pageList.provider.join(','),
            trackWayId: this.pageList.logisticsMode.join(','),
            label: this.pageList.orderTags.join(','),  // 订单标签
            statusLabel: this.statusLabelId?this.statusLabelId:"",
            orderStatus: this.pageList.orderState.join(','),
            topStatus: this.tabName,
            dateType: this.pageList.dateType,
            startDate: this.pageList.startDate,
            endDate: this.pageList.endDate
          }
          handleFilteAudit({...params, ...TempData})
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              search(this.pageList.profileId, this.tabName, this.statusLabelId)
            }
          })
        })
      }else if(command == 2) {   // 预发货
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        }  
        let ids = this.multipleSelection.map(t=> t.id);
        this.$confirm(`共${this.multipleSelection.length}条订单, 确定预发货?预发货后将不锁定库存提前物流下单`, '预发货?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            ids: ids,
            index: 3,     // 预发货
            type: "audit"
          }
          handleOperate(data)
          .then(res=> {
            console.log(res, '00000000000');
            this.auditFailList = [];
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.search(this.pageList.profileId, this.tabName);
            }
          })
        }).catch(() => {});
      }else if(command == 3) {   // 取消订单
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        }  
        let ids = this.multipleSelection.map(g=> g.id);
        this.$confirm("仅针对内部取消订单发货, 不影响平台订单状态", '取消订单', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            ids: ids,
            index: 4,
            type: "audit"
          }
          handleOperate(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.search(this.pageList.profileId, this.tabName);
            }
          })
        }).catch(() => {});
      }
    },
    // 发货设置
    handleCommand2(command) {
      if(command == 0) {        // 设定仓库
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;
        }
        this.formShipments.storageId = "";
        this.storeVisible = true;
      }else if(command == 1) {   // 设定物流
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        } 
        this.formShipTrackWay.shipTrackWayId = "";
        this.shipTrackWayVisible = true;
      }
    },
    // 设置仓库
    handleSetStore() {
      if(!this.formShipments.storageId) {
        this.$message.error('请选择发货仓库');
        return false;
      }
      let ids = this.multipleSelection.map(t=> t.id);
      let data = {
        ids: ids,
        index: 0,
        type: "ship",
        value: this.formShipments.storageId
      }
      handleOperate(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
          this.storeVisible = false;
          this.search(this.pageList.profileId, this.tabName);
        }
      })
    },
    // 设置物流
    handleShipTrackWay() {
      if(!this.formShipTrackWay.shipTrackWayId) {
        this.$message.error('请选择物流方式');
        return false;
      } 
      let ids = this.multipleSelection.map(e=> e.id);
      let data = {
        ids: ids,
        index: 1,
        type: "ship",
        value: this.formShipTrackWay.shipTrackWayId
      }
      handleOperate(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
          this.shipTrackWayVisible = false;
          this.search(this.pageList.profileId, this.tabName);
        }
      })
    },
    // 合并拆分
    handleCommand3(command) {
      if(command == 0) {          // 订单合并
        if(this.multipleSelection.length<2) {  
          this.$message.error('请至少选择2个订单');
          return false;  
        }   
        let ids = this.multipleSelection.map(d=> d.id);
        this.$confirm("合并勾选项相同店铺, 买家, 收件人, 收货地址的订单", '订单合并订单?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            ids: ids,
            index: 0,
            type: "order"
          }
          handleOperate(data)
           .then(res=> {
             console.log(res, '123456123');
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.$message.success(res.data.message);
               this.search(this.pageList.profileId, this.tabName);
             }
           })
        }).catch(() => {});
      }else if(command == 1) {    // 订单拆分
        if(this.multipleSelection.length>1) {
          this.$message.error('单次仅能操作一个订单');
          return false;
        }
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;
        }
        this.orderSpliList = [];
        this.multipleSelection[0].items.forEach(item=> {
          let object = {
            url: item.url,
            asin: item.asin,
            sku: item.sku,
            productName: item.productName,
            count: item.count,
            id: item.id,
            splitCount: 0
          }
          this.orderSpliList.push(object);   // 订单拆分集合数据
        })
        this.arrayList = [];  
        console.log(this.orderSpliList);
        this.orderSplitVisible = true;
      }
    },
    // 生成拆分预览
    handleSplitPreview() {
      const test = (ele) => {
        return ele == 0;
      }
      let splitCounts = this.orderSpliList.map(s=> s.splitCount);   // 拆分数量
      let flag = splitCounts.every(test);   //  判断每一项的值是否为0
      console.log(flag);
      if(flag) {
        this.$message.error('请输入拆分数量');
        return false;
      }
      let splitLists = [];  // 外围循环数据
      for (let index = 0; index<this.orderSpliList.length; index++) {
        if(this.orderSpliList[index].splitCount!=0) {
          this.orderSpliList[index].count = this.orderSpliList[index].count - this.orderSpliList[index].splitCount;  // 剩余数量
          splitLists.push({...this.orderSpliList[index], num:this.orderSpliList[index].splitCount});
        }
      }
      this.arrayList.push(splitLists);   // 追加最外层数据
      for (let index = 0; index<this.orderSpliList.length; index++) {
        if(this.orderSpliList[index].count<this.orderSpliList[index].splitCount) {
          this.orderSpliList[index].splitCount = this.orderSpliList[index].count;
          // 当剩余拆分数小于0时, 禁止拆分
          if(this.orderSpliList[index].count<0) {
            this.$message.error('请输入拆分数');
            return false;
          }
        }
      }
    },
    // 确认拆分
    handleConfirmSplit(){
      let splitLists = [];   // 外围循环数据
      for (let index = 0; index<this.orderSpliList.length; index++) {
        if(this.orderSpliList[index].count>0) {
          splitLists.push({...this.orderSpliList[index], num:this.orderSpliList[index].count});
          this.arrayList.push(splitLists);     // 追加最外层数据
          // this.orderSpliList[index].count = 0;
        }
      }
      let ids = this.multipleSelection.map(t=> t.id);
      let spiltOrder = [];  // 最外层数据
      let array = [];       // 内层数据
      console.log(this.arrayList, 'kkkkkkkkkkk');
      this.arrayList.forEach(data=> {
        data.forEach(item=> {
          let object = {
            count: item.num,
            id: item.id
          }
          array.push(object);
        })
      })
      spiltOrder.push(array);
      let data = {
        ids: ids,
        index: 1,
        spiltOrder: spiltOrder,
        type: "order"
      }
      handleOperate(data)
       .then(res=> {
         console.log(res);
         if(res.data.code == 300) {
          this.splitOrderList = res.data.data;
          this.orderSplitVisible = false;
          this.splitResultVisible = true;
       }else {
         this.$message.success(res.data.message);
         this.orderSplitVisible = false;
       }
      })
    },
    // 客服处理
    handleCommand4(command) {   // 客服备注
      if(command == 0) {
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        }
        this.customerContent = "";  // 内容清空
        this.customerVisible = true;
      }else if(command == 1) {     // 留言已处理
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        } 
        let ids = this.multipleSelection.map(n=> n.id);
        this.$confirm(`共${this.multipleSelection.length}个订单, 确认操作留言处理吗?`, '留言已处理?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            ids: ids,
            index: 1,
            type: "cs"
          }
          handleOperate(data)
          .then(res=> {
            if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }else {
              this.$message.success(res.data.message);
              this.customerVisible = false;
              this.search(this.pageList.profileId, this.tabName)
            }
          })
        }).catch(() => {});
      }
    },
    // 客服备注保存
    handleConfirm() {
      if(!this.customerContent) {
        this.$message.error('客服备注不能为空');
        return false;
      }
      let ids = this.multipleSelection.map(g=> g.id);
      let data = {
        ids: ids,
        index: "0",
        type: "cs",
        value: this.customerContent
      }
      handleOperate(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
          this.customerVisible = false;
          this.search(this.pageList.profileId, this.tabName);
        }
      })
    },
    // 更多操作
    handleCommand5(command) {
      if(command == 0) {           // 打标签
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;
        }
        this.getTagsLabelList();
        this.markTagsVisible = true;
      }else if(command == 1) {    // 转待处理
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        }   
        this.getTagsDealList(); 
        this.pendingVisible = true;
      }else if(command == 2) {     // 批量编辑
        if(!this.multipleSelection.length) {
          this.$message.error('请选择要操作订单');
          return false;  
        } 
        this.handleEditVisible = true;
      }
    },
    // 批量编辑保存
    handleBatchEdit() {
      if(!this.checkTypeList.length) {
        this.$message.error('请至少选中一个要修改的内容');
        return false;
      }
      let ids = this.multipleSelection.map(g=> g.id);
      let data = {
        ids: ids,
        index: 2,
        type: "other",
        value: this.checkTypeList.join(',') 
      }
      handleOperate(data)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.$message.success(res.data.message);
           this.handleEditVisible = false;
           this.search(this.pageList.profileId, this.tabName);
         }
       })
    },
    // 标记标签
    handleManageConfirm() {
      if(!this.checked.length) {
        this.$message.error('请选择要标记标签');
        return false;
      }
      if(!this.multipleSelection.length) {
        this.$message.error('请选择要操作的订单');
        return false;
      }
      let ids = this.multipleSelection.map(h=> h.id);
      let data = {
        ids: ids,
        index: 0,
        type: "other",
        value: this.checked.join(',')    // 拿到每一标签的id
      }
      handleOperate(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
          this.markTagsVisible = false;
          this.search(this.pageList.profileId, this.tabName);
        }
      })
    },
    changeChecked() {},
    handleInputSave() {     // 截单原因
      if(!this.input_Value.trim()) {
        this.$message.error('标签名称不能为空');
        return false;
      }
      let input_Value = this.input_Value.trim();
      const test = (ele) => {
        return ele == input_Value;
      };
      let checkListValue = this.checkList.map(x=> x.value);
      let flag = checkListValue.some(test);
      if(flag) {
        this.$message.error('标签已存在, 禁止新增');
        this.input_Value = "";   // 数据清空
        return false;
      }
      let params = {
        name: this.input_Value,
        type: 0
      }
      handleSetLabel(params)
       .then(res=> {
         console.log(res);
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.$message.success(res.data.message);
           this.getTagsDealList();
         }
       })
      this.input_Visible = false;
      this.input_Value = '';
    },
    handleShowInput() {
      this.input_Visible = true;
      this.$nextTick(_ => {
        this.$refs.keepTagInput.$refs.input.focus();
      });
    },
    changeChecked() {},
    // 转待处理
    handleSure() {
      if(!this.isCheckList) {
        this.$message.error('标签绑定出错');
        return false;
      }
      let ids = this.multipleSelection.map(q=> q.id);
      let data = {
        ids: ids,
        index: 1,
        type: "other",
        value: this.isCheckList.id
      }
      handleOperate(data)
      .then(res=> {
        if(res.data.code == 500) {
          this.$message.error(res.data.message);
        }else {
          this.$message.success(res.data.message);
          this.pendingVisible = false;
          this.search(this.pageList.profileId, this.tabName);
        }
      })
    },
    getProductList() {  // 获取商品信息列表
      let params = {
        current: this.productList.current,
        keyword: this.productList.keyword,
        pageSize: this.productList.pageSize,
      }
      getProductListPage(params)
       .then(res=> {
         if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }else {
           this.productDataList = res.data.values;
           this.productTotal = res.data.pageInfo.total;
         }
      })
    },
    // 添加商品
    handleAddGoods(type) {
     this.type = 0;
     this.getProductList();
     this.addProductVisible = true;
    },
    handleSelectionProduct(val) {
      this.multipleSelectionRow = val;
    },
    handleSizeProduct(newSize) {
      this.productList.pageSize = newSize;
      this.getProductList();
    },
    handleCurrentProduct(newCurrent) {
      this.productList.current = newCurrent;
      this.getProductList();
    },
    handleSelectGood() {
      this.multipleSelectionRow.forEach(item=> {
        let object = {
          id: item.id,
          // skuId:   // 产品Id
          asin: item.commoditySku,   // sku
          productName: item.productName,   // 品名
          url: item.productImagePath,   // 图片
        }
        this.goodsInforList.push(object);
      })
      this.addProductVisible = false;
    },
    checkBoxDisable(row) {
      let currentIds = this.goodsInforList.map(e=> e.id);
      if(currentIds.includes(row.id)) {
        return false;
      }else {
        return true;
      }
    },
    handleRemove(index) {    // 移除
      this.goodsInforList.splice(index, 1);  // 根据索引移除
    },
    handleMatches(type, index) {   // 配对
      console.log(index);
      this.optionIndex = index;
      this.type = 1;
      this.getProductList();
      this.addProductVisible = true;
    },
    handleChange(type, index) {    // 换货
      console.log(index);
      this.optionIndex2 = index;
      this.type = 2;
      this.getProductList();
      this.addProductVisible = true;
    },
    // (换货和配对)选择事件
    handleChoose(index, row) {     // 选择商品赋值事件
      console.log(index, row);
      for (let index = 0; index < this.goodsInforList.length; index++) {    // 换货
       this.goodsInforList[this.optionIndex].productName = row.productName;
       this.goodsInforList[this.optionIndex].sku = row.commoditySku;
      }
      this.addProductVisible = false;
    },
    handleExchange(index, row) {
      console.log(index, row);
      for (let index = 0; index < this.goodsInforList.length; index++) {    // 换货
       this.goodsInforList[this.optionIndex2].productName = row.productName;
       this.goodsInforList[this.optionIndex2].sku = row.commoditySku;
      }
      this.addProductVisible = false;
    },
    // 客服编辑
    handleTips(id, tips) {
      this.$prompt('请输入客服备注', '编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: tips,
      }).then(({ value }) => {
        let ids = [];
        ids.push(id);
        let data = {
         ids: ids,
         type: "cs",
         index: "2",
         value: value
        }
        handleOperate(data)
        .then(res=> {
          if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }else {
            this.$message.success(res.data.message);
            this.search(this.pageList.profileId, this.tabName);
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.queryClass {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  .el_elsect {
    margin-right: 15px;
  }
}
.buttonGroup {
  margin-top: 15px;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.inboundManual{
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
    .content{
      flex: 1;
      overflow: auto;
      padding-top: 10px;
    }
}
.look {
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
}
.fn {
  width: 40%;
  height: 40px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 2%;
  text-align: center;
}
.lable {
  width: 30%;
  height: 38px;
  float: left;
  line-height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px 0px 0px 4px;
}
.value {
  width: 65%;
  height: 40px;
  float: left;
  line-height: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: left;
  padding-left: 50px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-left: none;
  color: #666666ff;
  border-radius: 0px 4px 4px 0px;
}
.el-dialog-div{
  // height: 100vh; // 设置固定高度
  overflow: auto;
}
.picture{
  width:160px;
  height: 160px;
  font-size:8px;
  color: #909399;
  text-align: center;
  line-height: 160px;
  margin-right: 10px;
  overflow: hidden;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.infoText{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 25px;
  margin: 15px 0 20px 0;
}
.suspend{
  p{
    span{
      display: inline-block;
      width: 180px;
      margin-right: 10px;
      font-size: 13px;
    }
  }
}
.noPic{
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size:8px;
  color: #909399;
  margin-right: 15px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.goodsInfo{
  display: flex;
  >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  p{
    font-size: 12px;
    color: #909399;
  }
}
</style>