import request from '@/utils/request'
// 自发货订单模块

// 查询初始化
export function pageInit() {
  return request({
    url: "/api/order/fbm/pageInit",
    method: "get"
  })
}

// 查询分页
export function listPage(params) {
  return request({
    url: "/api/order/fbm/listPage",
    params,
    method: "get"
  })
}

// 根据id 查看详情
export function handleDetail(id) {
  return request({
    url: `/api/order/fbm/${id}`,
    method: "get"
  })
}

// 编辑初始化
export function handleUpdateInit(id) {
  return request({
    url: `/api/order/fbm/updateInit/${id}`,
    method: "get"
  })
}

// 编辑事件
export function editFbm(data) {
  return request({
    url: "/api/order/fbm",
    data,
    method: "put"
  })
}

// 操作事件
export function handleOperate(data) {
  return request({
    url: "/api/order/fbm/operate",
    data,
    method: "put"
  })
}

// 所有标签状态
export function getTagsDeal(params) {
  return request({
    url: "/api/order/fbm/tagsDeal",
    params,
    method: "get"
  })
}

// 所有标记标签
export function getTagsLabel(params) {
  return request({
    url: "/api/order/fbm/tagsLabel",
    params,
    method: "get"
  })
}

// 新增状态标签
export function handleSetLabel(params) {
  return request({
    url: "/api/order/fbm/setLabel",
    params,
    method: "get"
  })
}

// 操作筛选审核
export function handleFilteAudit(params) {
  return request({
    url: "/api/order/fbm/operate",
    params,
    method: "get"
  })
}